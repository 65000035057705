<template>
  <div>
    <ShopBanner />
    <section class="section">
      <div class="container">
        <div class="level">
          <div class="level-left">
            <h3 class="title is-3 is-bold">Bestellungsvorgang</h3>
          </div>
          <div class="level-right">
            <button class="button is-medium" @click="goToShop">
              Zur Artikelübersicht
            </button>
          </div>
        </div>
        <br />

        <b-steps
          v-model="activeStep"
          :animated="true"
          :rounded="false"
          :has-navigation="false"
          mobile-mode="compact"
        >
          <hr />
          <b-step-item step="1" label="Adresse" :clickable="isStepClickable(1)">
            <div class="content">
              <h1 class="title is-4">Adresse</h1>
              <p class="subtitle">Bitte füllen Sie Ihre Informationen aus.</p>
              <div class="columns">
                <div class="column is-6">
                  <p class="subtitle is-5"><b>Personalien</b></p>

                  <div class="field">
                    <label class="label">Anrede*</label>
                    <div class="control">
                      <div class="select">
                        <select v-model="customer.person.salutation">
                          <option>Herr</option>
                          <option>Frau</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <label class="label">Firma / Schule</label>
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        v-model="customer.person.company"
                      />
                    </div>
                  </div>

                  <div class="field">
                    <label class="label">Vorname*</label>
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        v-model="customer.person.firstname"
                      />
                    </div>
                  </div>

                  <div class="field">
                    <label class="label">Nachname*</label>
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        v-model="customer.person.familyname"
                      />
                    </div>
                  </div>

                  <div class="field">
                    <label class="label">Strasse*</label>
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        v-model="customer.person.street"
                      />
                    </div>
                  </div>

                  <div class="field">
                    <label class="label">Ort*</label>
                    <div class="columns">
                      <div class="column is-5">
                        <div class="control">
                          <input
                            class="input"
                            type="number"
                            placeholder="Postleitzahl"
                            v-model="customer.person.plz"
                          />
                        </div>
                      </div>
                      <div class="column">
                        <input
                          class="input"
                          type="text"
                          placeholder="Ort"
                          v-model="customer.person.place"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <label class="label">Land*</label>
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        v-model="customer.person.country"
                        disabled="true"
                        title="Lieferungen ausschliesslich in der Schweiz"
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <p class="subtitle is-5"><b>Kontakt</b></p>

                  <div class="field">
                    <label class="label">E-Mail*</label>
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        v-model="customer.person.email"
                      />
                    </div>
                  </div>

                  <div class="field">
                    <label class="label">Telefon</label>
                    <div class="control">
                      <input
                        class="input"
                        type="telephone"
                        v-model="customer.person.telephone"
                      />
                    </div>
                  </div>

                  <div class="field">
                    <label class="label">Bemerkung</label>
                    <div class="control">
                      <textarea
                        class="textarea"
                        placeholder="Anmerkungen, Besonderheiten, etc..."
                        v-model="customer.order.notes"
                      ></textarea>
                    </div>
                  </div>

                  <div class="buttons is-right">
                    <button
                      class="button is-primary is-outlined is-medium"
                      @click="goToNextStep"
                    >
                      Zum nächsten Schritt
                    </button>
                  </div>

                  <div class="notification is-danger" v-if="validation_error">
                    {{ validation_error }}
                  </div>
                </div>
              </div>
            </div>
          </b-step-item>

          <b-step-item step="2" label="Versand" :clickable="isStepClickable(2)">
            <div class="content">
              <h1 class="title is-4">Versand</h1>

              <div class="columns">
                <div class="column is-6">
                  <p class="subtitle">Bitte wählen Sie Ihre Versandoption</p>
                  <div class="field">
                    <div class="control">
                      <label class="radio">
                        <input
                          type="radio"
                          name="supply"
                          value="post"
                          checked="checked"
                          v-model="customer.order.supply"
                        />
                        Versand per Post
                      </label>
                      <br />
                      <label class="radio">
                        <input
                          type="radio"
                          name="supply"
                          value="takeaway"
                          v-model="customer.order.supply"
                        />
                        Abholung durch Käufer an Lageradresse (CHF 0.00)
                      </label>
                    </div>
                  </div>

                  <br />
                  <div class="notification">
                    <p class="subtitle is-6"><b>Lieferfrist</b></p>
                    <p v-if="isPostVersand">
                      Ihr Paket wird Ihnen nach Eingang der Zahlung innerhalb
                      von <b>2-3 Werkstagen</b> per Post zugestellt.
                    </p>
                    <p v-else>
                      Ihr Paket wird nach <b>Eingang Ihrer Zahlung</b> verpackt
                      und an unserer Lageradresse hinterlegt. Wir kontaktieren
                      Sie für die Abholung Ihres Paketes.
                    </p>
                  </div>
                </div>
                <div class="column is-6">
                  <p>
                    <b>Lageradresse:</b><br />
                    Swiss Domino Effect, Bucherstrasse 2, 9322 Egnach,
                    Schweiz<br />
                    <a
                      href="https://goo.gl/maps/bohkr18UhB1TLtAt5"
                      target="_blank"
                      >In Google Maps öffnen</a
                    >
                  </p>
                  <div class="buttons is-right">
                    <button
                      class="button is-primary is-outlined is-medium"
                      @click="goToNextStep"
                    >
                      Zum nächsten Schritt
                    </button>
                  </div>

                  <div class="notification is-danger" v-if="validation_error">
                    {{ validation_error }}
                  </div>
                </div>
              </div>
            </div>
          </b-step-item>

          <b-step-item
            step="3"
            label="Übersicht"
            :clickable="isStepClickable(3)"
          >
            <b-loading
              :is-full-page="false"
              v-model="is_loading"
              :can-cancel="false"
            ></b-loading>
            <div class="content is-medium">
              <h1 class="title is-4">Übersicht</h1>
              <table class="table is-striped is-fullwidth" v-if="shopping_cart">
                <tr>
                  <th>Artikel</th>
                  <th width="20px"></th>
                  <th width="80px" colspan="1" align="right">Betrag</th>
                </tr>
                <tr v-for="(product, index) in shopping_cart" :key="index">
                  <td>
                    {{ display_position(product) }}
                  </td>
                  <td>CHF</td>
                  <td align="right">
                    {{ formatNumberToTwoDigits(sum_position(product)) }}
                  </td>
                </tr>
                <tr>
                  <th>Summe</th>
                  <th>CHF</th>
                  <th align="right">
                    {{ formatNumberToTwoDigits(sum_all_positions()) }}
                  </th>
                </tr>
              </table>

              <div class="notification is-error" v-if="validation_error">
                {{ validation_error }}
              </div>

              <div class="level">
                <div class="level-left">
                  <div class="field has-addons">
                    <div class="control">
                      <input
                        class="input is-medium"
                        type="text"
                        placeholder="Vergünstigungscode"
                        v-model="customer.order.reduction_code"
                      />
                    </div>
                    <div class="control">
                      <button
                        class="button is-medium is-primary is-outlined"
                        @click="applyVoucherCode"
                        :disabled="
                          !customer.order.reduction_code ||
                          customer.order.reduction_code.length != 8
                        "
                      >
                        Einlösen
                      </button>
                    </div>
                  </div>
                </div>
                <div class="level-right">
                  <button
                    class="button is-primary is-outlined is-medium"
                    @click="goToNextStep"
                  >
                    Zum nächsten Schritt
                  </button>
                </div>
              </div>
            </div>
          </b-step-item>

          <b-step-item
            :step="4"
            label="Zahlung"
            :clickable="isStepClickable(4)"
            disabled
          >
            <div class="content">
              <h1 class="title is-4">Zahlungsart</h1>
              <div class="field">
                <div class="control">
                  <label class="radiobox">
                    <input
                      type="radio"
                      value="prepayment"
                      v-model="customer.order.payment_method"
                    />
                    Vorauskasse (Banküberweisung / Einzahlung von
                    Rechnungsbetrag)
                  </label>
                </div>
              </div>
              <div class="field" v-if="!isPostVersand">
                <div class="control">
                  <label class="radiobox">
                    <input
                      type="radio"
                      value="cashpayment"
                      v-model="customer.order.payment_method"
                    />
                    Barzahlung (Direkt an Lageradresse)
                  </label>
                </div>
              </div>
              <div
                class="notification is-light"
                v-if="customer.order.payment_method == 'prepayment'"
              >
                Sobald uns der entsprechende Betrag gutgeschrieben wurde, werden
                wir Ihre Bestellung verpacken und an Sie versenden oder zur
                Abholung bereitlegen.
              </div>
              <div
                class="notification is-light"
                v-if="customer.order.payment_method == 'cashpayment'"
              >
                Bitte bringen Sie für die Barzahlung den fälligen Betrag
                <strong
                  >CHF
                  {{ formatNumberToTwoDigits(sum_all_positions()) }}</strong
                >
                passend mit.
              </div>
              <div class="buttons is-right">
                <button
                  class="button is-primary is-outlined is-medium"
                  @click="goToNextStep"
                >
                  Zum nächsten Schritt
                </button>
              </div>
            </div>
          </b-step-item>

          <b-step-item
            :step="5"
            label="Bestätigung"
            :clickable="isStepClickable(5)"
            disabled
          >
            <div class="content" v-if="!created_order_id">
              <div class="field">
                <div class="control">
                  <label class="checkbox">
                    <input type="checkbox" v-model="agb_verified" />
                    Ich akzeptiere die
                    <a @click="handleAgbClick">
                      Allgemeinen Geschäftsbedingungen</a
                    >. *
                  </label>
                </div>
              </div>

              <div class="buttons is-left">
                <button
                  class="button is-primary is-medium"
                  @click="submitOrder"
                  :disabled="!agb_verified"
                  :class="{ 'is-loading': is_loading }"
                >
                  Jetzt Bestellung absenden
                </button>
              </div>

              <div class="notification is-danger" v-if="error_text">
                {{ error_text }}
              </div>

              <AGB :handleAgbClick="handleAgbClick" :shown="agb_shown" />
            </div>
            <div class="content" v-show="created_order_id">
              <h4 class="title is-4">Vielen Dank für Ihre Bestellung</h4>

              <hr />
              <p class="subtitle is-5">
                <b
                  >Wir haben Ihre Bestellung erhalten und senden Ihnen in Kürze
                  eine Bestätigungs-Email. Überprüfen Sie bei Nichterhalt Ihren
                  Spam-/Junkordner.</b
                >
              </p>
              <p v-if="isPostVersand">
                Sobald Ihre Zahlung bei uns eingegangen ist, werden wir Ihre
                Bestellung versenden.
              </p>
              <p v-else>
                Sobald Ihre Zahlung bei uns eingangen und Ihre Bestellung
                abholbereit ist, werden wir uns bei Ihnen melden.
              </p>
              <a
                class="button is-primary is-outlined"
                v-bind:class="{ 'is-loading': loading_document }"
                v-if="true"
                :href="bill_link"
                ><span class="icon is-small is-left">
                  <i class="fas fa-file-pdf"></i> </span
                >&nbsp;Rechnung jetzt herunterladen</a
              >
            </div>
          </b-step-item>
        </b-steps>
      </div>
    </section>
  </div>
</template>
<script>
import ShopBanner from "./ShopBanner.vue";
import AGB from "./AGB.vue";
import { load, pay, applyVoucherCode } from "./shopping_cart_helper";
import { loadBillDocument } from "@/webapi";
export default {
  components: {
    ShopBanner,
    AGB,
  },
  metaInfo: {
    title: "Bestellungsvorgang",
  },
  watch: {
    versandArt: {
      handler: function (oldObj, newObj) {
        this.loadShoppingCart();
      },
      deep: true,
    },
  },
  data: function () {
    return {
      activeStep: 0,
      customer: {
        person: {
          salutation: null,
          company: null,
          firstname: null,
          familyname: null,
          street: null,
          plz: null,
          place: null,
          country: "Schweiz",
          email: null,
          telephone: null,
        },
        order: {
          supply: "post",
          notes: null,
          payment_method: "prepayment",
          reduction_code: null,
        },
      },
      agb_verified: false,
      agb_shown: false,
      error_text: null,
      validation_error: null,
      is_loading: false,
      is_completed: false,
      rerender_counter: 0,
      created_order_id: null,
      loading_document: false,
      bill_link: null,
      shopping_cart: null,
    };
  },
  created() {
    this.loadShoppingCart();
  },
  methods: {
    loadShoppingCart() {
      load(this.session_id, this.isPostVersand, (resp) => {
        if (resp.state) {
          this.shopping_cart = resp.products;
        } else {
          // no shopping cart loaded -> redirect to shop page
          this.$router.push({ name: "shop" });
        }
      });
    },
    goToNextStep() {
      this.validation_error = null;
      if (this.activeStep == 0) {
        if (this.validateCustomerData()) {
          this.activeStep = 1;
        } else {
          this.validation_error =
            "Bitte füllen Sie alle Pflichtfelder korrekt aus.";
        }
      } else if (this.activeStep == 1) {
        if (this.validateDeliveryOption()) {
          this.activeStep = 2;
        } else {
          this.validation_error =
            "Bitte wählen Sie eine gültige Versandoption.";
        }
      } else if (this.activeStep == 2) {
        this.activeStep = 3;
      } else if (this.activeStep == 3) {
        if (this.validatePaymentOption()) {
          this.activeStep = 4;
        } else {
          this.validation_error =
            "Bitte wählen Sie eine gültige Zahlungsmethode aus.";
        }
      }
    },
    isStepClickable(step) {
      if (this.created_order_id) {
        return false;
      }
      return this.activeStep > step - 1;
    },
    validateCustomerData() {
      let person = this.customer.person;
      return !this.isNullOrEmpty([
        person.salutation,
        person.firstname,
        person.familyname,
        person.street,
        person.plz,
        person.place,
        person.country,
        person.email,
      ]);
    },
    validateDeliveryOption() {
      let option = this.customer.order.supply;
      return option == "post" || option == "takeaway";
    },
    validatePaymentOption() {
      let option = this.customer.order.payment_method;
      if (this.isPostVersand) {
        return option == "prepayment"; //todo: wird bei einbau von zahlungsmethoden anschlagen
      }
      return option == "cashpayment" || option == "prepayment";
    },
    isNullOrEmpty(values) {
      for (var i = 0; i < values.length; i++) {
        let val = values[i];
        if (val == null || val == "") {
          return true;
        }
      }
      return false;
    },
    formatNumberToTwoDigits(number) {
      return Number(number).toFixed(2);
    },
    sum_position(product) {
      let price = product.product_price.product;
      if (price) {
        let product_count = product.product_count ? product.product_count : 1;
        return product_count * price;
      }
      return 0;
    },
    sum_all_positions() {
      if (this.shopping_cart) {
        return this.shopping_cart.reduce(
          (partialSum, product) =>
            partialSum + Number(this.sum_position(product)),
          0
        );
      }
      return 0;
    },
    applyVoucherCode() {
      this.validation_error = null;
      let voucher = this.customer.order.reduction_code;
      if (!voucher || voucher.trim() == "" || voucher.length != 8) {
        this.validation_error = "Bitte geben Sie einen gültigen Code an.";
        return;
      }
      this.is_loading = true;
      applyVoucherCode(this.session_id, voucher, (resp) => {
        this.is_loading = false;
        if (resp.state) {
          this.loadShoppingCart();
          this.$buefy.toast.open({
            duration: 5000,
            message: "Vergünstigungscode konnte erfolgreich eingelöst werden.",
            type: "is-success",
          });
        } else {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Vergünstigungscode konnte nicht eingelöst werden.",
            type: "is-danger",
          });
        }
      });
    },
    handleAgbClick() {
      this.agb_shown = !this.agb_shown;
    },
    goToShop() {
      if (!this.created_order_id) {
        this.$buefy.dialog.confirm({
          title: "Zur Artikelübersicht wechseln?",
          message:
            "Möchten Sie wirklich zur Artikelübersicht? Alle Ihre Eingaben gehen dabei verloren.",
          cancelText: "Abbrechen",
          confirmText: "Zur Artikelübersicht",
          type: "is-warning",
          hasIcon: true,
          onConfirm: () => this.$router.push({ name: "shop" }),
          onCancel: () => {},
        });
      } else {
        this.$router.push({ name: "shop" });
      }
    },
    submitOrder() {
      this.error_text = null;
      this.is_loading = true;
      pay(this.session_id, this.customer, (resp) => {
        this.is_loading = false;
        if (resp.state) {
          this.created_order_id = resp.id;
          this.$cookies.remove("sct");
          this.$cookies.remove("scc");
          this.loadBillAfterOrderSubmit();
        } else {
          this.error_text = "Es ist ein Fehler aufgetreten.";
        }
      });
    },
    display_position(product) {
      if (product.product_count) {
        return `${product.product_count} x ${product.product_name}`;
      }
      return product.product_name;
    },
    loadBillAfterOrderSubmit() {
      this.loading_document = true;
      loadBillDocument(this.created_order_id)
        .then((resp) => {
          this.loading_document = false;
          if (resp.data.state) {
            this.bill_link = resp.data.file;
            return;
          }
          this.showBillLoadingError();
        })
        .catch(() => this.showBillLoadingError());
    },
    showBillLoadingError() {
      alert(
        "Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie uns unter info@swissdominoeffect.com."
      );
      this.loading_document = false;
    },
  },
  computed: {
    session_id() {
      return this.$cookies.get("sct");
    },
    isPostVersand() {
      return this.versandArt == "post";
    },
    versandArt() {
      return this.customer.order.supply;
    },
  },
};
</script>